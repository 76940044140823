import { Module } from "vuex";

import { api } from "@/util/axios";
import { Bank, Payout, Subaccount, TransferRecipient } from "@/types";

type PaystackState = {
  transferRecipient: TransferRecipient | undefined;
  banks: Bank[];
  payoutList: Payout[];
  subaccount: Subaccount | undefined;
  transactionList: any[];
};

const paystack: Module<PaystackState, unknown> = {
  namespaced: true,
  state: () => ({
    transferRecipient: undefined,
    banks: [],
    payoutList: [],
    subaccount: undefined,
    transactionList: [],
  }),
  getters: {
    getTransferRecipient: (state) => state.transferRecipient,
    banks: (state) => state.banks,
    payoutList: (state) => state.payoutList,
    transactionList: (state) => state.transactionList,
  },
  mutations: {
    SET_TRANSFER_RECIPIENT: (state, recipient) => {
      state.transferRecipient = recipient;
    },
    REMOVE_TRANSFER_RECIPIENT(state) {
      state.transferRecipient = undefined;
    },
    SET_BANKS(state, bnks) {
      state.banks = bnks;
    },
    SET_PAYOUTS(state, list) {
      state.payoutList = list;
    },
    SET_SUBACCOUNT(state, account) {
      state.subaccount = account;
    },
    SET_TRANSACTION_LIST(state, list) {
      state.transactionList = list;
    },
  },
  actions: {
    fetchTransferRecipient(context, params = "") {
      api
        .get(`/v1/paystack/transfer/recipient${params}`)
        .then((response) => {
          context.commit(
            "SET_TRANSFER_RECIPIENT",
            response.data.transferRecipient
          );
        })
        .catch((error) => {
          // context.dispatch(
          //   "setToast",
          //   {
          //     title: "Request failed!",
          //     type: "error",
          //     text: error.response?.data?.error?.message,
          //   },
          //   { root: true }
          // );
        });
    },
    async fetchBanks(context, params = "") {
      return await api
        .get(`/v1/paystack/bank${params}`)
        .then((response) => {
          context.commit("SET_BANKS", response.data.banks);
          return response.data.banks;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchSubaccount(context, params = "") {
      return await api
        .get(`/v1/paystack-subaccount/${params}`)
        .then((response) => {
          context.commit("SET_SUBACCOUNT", response.data.subaccount);
          return response.data.subaccount;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchPayoutList(context, params = "") {
      api
        .get(`/v1/paystack/payout${params}`)
        .then((response) => {
          context.commit("SET_PAYOUTS", response.data.payouts);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createTransferRecipient(context, payload) {
      return await api
        .post(`/v1/paystack/transfer/recipient`, payload)
        .then((response) => {
          context.commit(
            "SET_TRANSFER_RECIPIENT",
            response.data.transferRecipient
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Gateway created",
            },
            { root: true }
          );
          return response.data.transferRecipient;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchTransactionList(context, params = "") {
      api
        .get(`/v1/paystack/transaction${params}`)
        .then((response) => {
          context.commit("SET_TRANSACTION_LIST", response.data.transactions);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateTransferRecipient(
      context,
      data: { id: string; transferRecipient: TransferRecipient }
    ) {
      return await api
        .put(
          `/v1/paystack/transfer/recipient/${data.id}`,
          data.transferRecipient
        )
        .then((response) => {
          context.commit(
            "SET_TRANSFER_RECIPIENT",
            response.data.transferRecipient
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Gateway updated",
            },
            { root: true }
          );
          return response.data.transferRecipient;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteTransferRecipient(context, id) {
      api
        .delete(`/v1/paystack/transfer/recipient${id}`)
        .then((response) => {
          context.commit(
            "REMOVE_TRANSFER_RECIPIENT",
            response.data.transferRecipient
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Gateway removed",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async initTransaction(context, payload) {
      return await api
        .post(`/v1/paystack/transaction/initialize`, payload)
        .then((response) => {
          return response.data.authorizationUrl;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createSubaccount(context, payload) {
      return await api
        .post(`/v1/paystack-subaccount`, payload)
        .then((response) => {
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Account Created",
            },
            { root: true }
          );
          return response.data.subaccount;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateSubaccount(context, { payload, code }) {
      return await api
        .put(`/v1/paystack-subaccount/${code}`, payload)
        .then((response) => {
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Account Updated",
            },
            { root: true }
          );
          return response.data.subaccount;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async initPlanTransaction(context, payload) {
      return await api
        .post(`/v1/paystack/transaction/plan/initialize`, payload)
        .then((response) => {
          return response.data.authorizationUrl;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async subscribeCustomer(context, payload) {
      return await api
        .post(`/v1/paystack/subscribe-customer`, payload)
        .then((response) => {
          return response.data.subscription;
        })
        .catch((error) => {
          let message = error.response?.data?.error?.message;

          if (message.includes("This subscription is already in place")) {
            message =
              "Please change/set your current business's email address in Settings > More to continue";
            context.dispatch(
              "setToast",
              {
                title: "Subscription Email must be unique!",
                type: "info",
                text: message,
                timeout: 60000,
              },
              { root: true }
            );
          } else {
            context.dispatch(
              "setToast",
              {
                title: "Request failed!",
                type: "error",
                text: message,
              },
              { root: true }
            );
          }
        });
    },
    async updateSubscription(context, payload) {
      return await api
        .put(`/v1/paystack/subscription`, payload)
        .then((response) => {
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Subscription Updated",
            },
            { root: true }
          );
          return response.data.subaccount;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createTransfer(context, payload) {
      return await api
        .post(`/v1/paystack/transfer/init-transfer`, payload)
        .then((response) => {
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Payout Initiated",
            },
            { root: true }
          );
          return response.data.transfer;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default paystack;
